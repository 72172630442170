export const createContainer = (key, withModal = false) => {
    const div = document.createElement("div")
    div.setAttribute(`data-${key}-container`, "")

    div.innerHTML = ""

    if (withModal) {
        const overlay = document.createElement("div")
        const modal = document.createElement("div")

        overlay.classList.add("public-modal-overlay")
        overlay.style.display = "none"

        modal.classList.add("public-modal-container")

        overlay.appendChild(modal)

        div.appendChild(overlay)
    }

    return document.body.insertBefore(div, document.body.firstChild)
}

export const getContainer = key => {
    return document.querySelector(`[data-${key}-container]`)
}
